<template>
	<div class="body">
		<navTop></navTop>

		<div class="wrap">
			<div class="wrap-left">
				<menuLeft :active="menuIndex" v-on:changeOrderStatus="orderStatusChange"></menuLeft>
			</div>
			
			
			<div class="wrap-right">
				<div class="order-list" v-for="(item,index) in order">
					<div class="order-list-header">
						<div>{{ $lang(item,"orderType") }}</div>
						<div>{{ item.orderCrestTime }}</div>
					</div>
					<table class="tab-header" border="0" cellpadding="0" cellspacing="0">
						<tr>
							<td style="text-align: left;padding:20px;">{{$t('userCenter.goodsInfo')}}</td>
							<td>{{$t('applyInvoice.type')}}</td>
							<td>{{$t('userCenter.price')}}</td>
							<td>{{$t('userCenter.number')}}</td>
						</tr>
						<tr v-for="goods in item.orderGoods">
							<td width="15%"><img :src="goods.img" width="80px" height="80px"></td>
							<td width="35%" style="text-align: left;">
							<div style="line-height:24px;">{{$lang(goods,"goodsInfo")}}</div>
							<div style="color:#888;margin-top:5px;">{{$lang(goods,"spec")}}</div>
							</td>
							<td width="20%">{{goods.type}}</td>
							<td width="20%">￥{{goods.price}}</td>
							<td>{{goods.number}}</td>
						</tr>
						<tr><td colspan="5" style="height: 20px;"></td></tr>
						<tr>
							<td style="background-color: #eee;color:#888;padding:10px 0px;">
								<el-checkbox :v-model="item.checked" style="margin-left: 25px;">{{$t('operation.select')}}</el-checkbox>
							</td>
							<td style="background-color: #eee;color:#888;">
								<span style="margin:0 90px;"><el-link @click="navTo('/userhome/ordercenter/detail')" :underline="false" style="font-size:12px;color:#555;">{{$t('applyInvoice.getOrderDetail')}}</el-link></span>
							</td>
							<td style="background-color: #eee;color:#888;">
							    <span style="color: #888;margin-right:5px;">{{ $t('applyInvoice.subtotal') }}</span>
								<span style="color:red;font-size: 16px;">￥{{ item.subtotal }}</span>
							</td>
							<td style="background-color: #eee;color:#888;">
								<el-button type="primary" size="mini" @click="orderDel()" v-if=" orderStatus == 3 ">{{ $t('orderCenter.orderDel') }}</el-button>
								<el-link style="color:red;font-size: 12px;" v-else>{{ $t('orderCenter.remindDelivery') }}</el-link>
							</td>
							<td style="background-color: #eee;color:#888;">
								<el-button type="primary" size="mini" @click="navTo(commentLink)" v-if=" orderStatus == 3 ">{{ $t('orderCenter.clickComment') }}</el-button>
								<p v-else>{{ $t("orderCenter.step4") }}</p>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import menuLeft from "@/components/MenuLeft";
	import navTop from "@/components/navTop";
	export default{
		components: {menuLeft,navTop},
		data(){
			return{
				orderStatus:"3",
				commentLink:"/userhome/ordercenter/comment",
				menuIndex:"1",
				order:[{
					orderTypeZhCn:'工品商城',
					ordertypeEnUs:'Products Mall',
					orderCrestTime:'2020-06-09 09:24:34',
					subtotal: "1288.00",
					payTimeZhCn:"交易时间：",
					payTimeEnUs:"Pay time：",
					payTime:'2020-06-09 09:24:34',
					checked:false,
					orderGoods: [{
						img:'https://',
						goodsInfoZhCn: '小型列管换热器 无菌换热器卫生及换热器微型换热器微通道换热器',
						goodsInfoEnUs:'Small tube heat exchanger aseptic heat exchanger sanitary heat exchanger miniature heat exchanger microchannel heat exchanger',
						specZhCn:'商品规格：FY-15ZDPIC',
						specEnUs:'Goods Specs：FY-15ZDPIC',
						type: '微通道换热器',
						price: '12800.00',
						number:'1'
					}]
					
				},
				{
					orderTypeZhCn:'工品商城',
					ordertypeEnUs:'Standard ordering',
					orderCrestTime:'2020-06-09 09:24:34',
					subtotal: "1288.00",
					payTimeZhCn:"交易时间：",
					payTimeEnUs:"Pay time：",
					payTime:'2020-06-09 09:24:34',
					checked:true,
					orderGoods: [{
						img:'https://',
						goodsInfoZhCn: '小型列管换热器 无菌换热器卫生及换热器微型换热器微通道换热器',
						goodsInfoEnUs:'Small tube heat exchanger aseptic heat exchanger sanitary heat exchanger miniature heat exchanger microchannel heat exchanger',
						specZhCn:'商品规格：FY-15ZDPIC',
						specEnUs:'Goods Specs：FY-15ZDPIC',
						type: '微通道换热器',
						price: '12800.00',
						number:'1'
					}]
				}],
			}
		},
		mounted() {
			
			this.load()
		},
		methods:{
			handleOpen(key, keyPath) {
				//console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				//console.log(key, keyPath);
			},
			navTo(url){
				//console.log(this.$route);
				if(url){
					
					this.$router.push(url)
					//this.$router.push({ path: url, query: { userId: 123 }});
				}
			},
			load(){
				let params = this.$route.query
				
				if(params.orderStatus){
					this.orderStatus = params.orderStatus
				}
			},
			orderStatusChange(data){
				console.log(data);
				this.orderStatus = data;
			}
			
		}
		
	}
</script>
<style  scoped>
	.body{
	}
	.nav{
		margin: 0 auto;
		width:1200px;
		margin-top: 40px;
		padding:20px 0;
		
	}
	.nav span{
		marign:0px 10px;
	}
	.el-link{
		font-size: 16px;
		font-weight: 600;
	}
	.wrap{
		margin: 0 auto;
		width:1200px;
		display: flex;
		justify-content: space-between;
	}
	.wrap-left{

		width:20%;
		min-height:800px;
		background-color: #fff;
		padding:20px 15px 40px;
	}
	
	.wrap-right{
		width:78%;
		min-height:800px;
		background-color: #fff;
		font-size:12px;
	}
	
	.order-list{
		margin-bottom: 40px;
	}
	.order-list-header{
		display: flex;
		justify-content: space-between;
		background-color: #555;
		color:#fff;
		padding:10px 30px;
		font-size: 14px;
	}
	.tab-header{
		width:100%;
	}
	.tab-header tr td{
		font-size: 14px;
		text-align: center;
		color: #888;
	}

</style>
